/* exported jmp */

var players = {};
var videoTimer;

$( document ).ready(function() {
  startYouTube();
});


function startYouTube(){
  //Loads the YouTube API

  if($(".YTplayer").length > 0){
    if(typeof(YT)=="undefined"){
      console.log("Initialising YouTube API script");
      var tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      onYouTubeIframeAPIReady();
    }
  } else {
    console.log("No YouTube videos detected");
  }
}


// gets page-specific videos information (in a separate page)
function onYouTubeIframeAPIReady() {
  $.each($(".YTplayer"),function(){
    var YTid = $(this).attr("YTid"),
      divID = $(this).attr("id"),
      YTstart = $(this).attr("YTstart"),
      YTend = $(this).attr("YTend");

    createPlayer(divID, YTid, YTstart, YTend);
    console.log("Creating youtube video " + YTid);
  });
}


function createPlayer(ref, videoID, start, end) {
  if (typeof start === "undefined") start = 0;
  if (typeof end === "undefined") end = 10800;
  players[ref] = new YT.Player(ref, {
    height: "360",
    width: "640",
    videoId: videoID,
    playerVars: {
      start: start,
      end: end,
      "showinfo": 0,
      playsinline: 1,
    },
    events: {
      "onStateChange": onPlayerStateChange
    }
  });
}


// to stop other video players once a video player starts playing
function onPlayerStateChange(event) {
  if(event.data == 1) {
    var temp = event.target.getVideoUrl();
    for(var i in players){
      if(players[i].getVideoUrl() != temp){
        players[i].pauseVideo();
      }
    }
  }
}


function jumpToTimecode(ref, timeStart, timeEnd){
  function pauseVideo() {
    players[ref].pauseVideo();
  }

  // as timeEnd is an optional argument
  if (typeof timeEnd === "undefined") timeEnd = 0;

  // first stops all other players
  for(var i in players){
    if(i!=ref){
      players[i].pauseVideo();
    }
  }

  // then jumps to the right place and starts playing
  players[ref].seekTo(timeStart);
  players[ref].playVideo();


  // if timeEnd was given, stops the video at the timeEnd timecode
  if (timeEnd != 0) {

    //+2 to account for starting and ending second
    var playDuration = ((timeEnd - timeStart)+2)*1000;
    window.clearTimeout(videoTimer);
    videoTimer = setTimeout(pauseVideo, playDuration);
  }
}

//Convenience reference
var jmp = jumpToTimecode;
